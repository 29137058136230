.home-carousel {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../../img/home-background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-img {
  margin: auto;
  width: 500px;
  box-shadow: 2px 2px;
}

.home-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../../img/homeBg1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white-color);
  animation: changeBg 50s infinite ease-in-out;
}
.home-container h2 {
  color: var(--white-color);
  font-size: 4rem;
  text-align: center;
  line-height: 2;
  font-family: var(--pacifico-family);
  animation: zoomInOut 4s linear infinite;
}
h2 span {
  line-height: 1.2;
  display: block;
  width: fit-content;
  padding: 6px 18px;
  border-radius: 5px;
  color: var(--primary-color);
  animation: none;
}
h2 {
  font-size: 1rem;
  color: var(--primary-color);
  font-family: var(--pacifico-family);
}
p {
  font-size: 18px;
  font-family: var(--sourceSansPro-family);
}
.home-content{
  line-height: 5;
}
.home-content-container {
  display: flex;
  align-items: inherit;
  justify-content: space-between;
}
.home-content-container .home-content {
  width: 60%;
}
.home-content-container img {
  width: 100%;
}

/* Animations */

@keyframes changeBg {
  0% {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(../../img/homeBg1.jpg);
  }
  20% {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(../../img/homeBg2.jpg);
  }
  40% {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(../../img/homeBg3.jpg);
  }
  60% {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(../../img/homeBg4.jpg);
  }
  80% {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(../../img/homeBg5.jpg);
  }
  100% {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(../../img/homeBg6.jpg);
  }
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
}

/* Media Query */

@media screen and (max-width: 900px) {
  .home-content-container {
    flex-direction: column;
  }
  .home-content-container .home-content {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 900px) {
  .home-content-container {
    align-items: inherit;
  }
}
