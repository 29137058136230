.infraContainer {
  background-color: black !important;
  /* background-image: url(../../img/infra.jpg) !important; */
}

.infraContent {
  width: 100vw;
  margin: auto;
  height: auto;
}

@media screen and (min-width: 1500px) {
  .infraContent {
    width: 50vw;
  }
}
