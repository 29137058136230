@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Source+Sans+Pro:wght@400;600;700;900&display=swap");

:root {
  --pacifico-family: "Pacifico", cursive;
  --sourceSansPro-family: "Source Sans Pro", sans-serif;
  --primary-color: #ff7700;
  --dark-color1: #000000;
  --dark-color2: #9784cc82;
  --white-color: white;
}
body {
  background: rgb(240, 255, 243);
}
.primaryTextColor {
  color: var(--primary-color) !important;
}
.marginBottom {
  margin-bottom: 2rem;
}
.commonContainer {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  text-align: center;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mainHeading {
  color: var(--white-color);
  font-family: var(--pacifico-family) !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 500px) {
  .css-1lugpt4-MuiTypography-root {
    font-size: 1.8rem !important;
  }
  .commonContainer {
    height: 50vh;
  }
}
